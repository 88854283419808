<template>
  <div class="adventure-sum-page">
    <!-- Header -->
    <div class="header adventure-sum-page__header">
      <h1 class="global-h1 header__h1">
        Full Adventure Previews on your Site
        <!--<span class="header__beta-badge">beta</span>-->
      </h1>
      <div class="header__second-line">
        <div class="header__subscript"></div>
      </div>
    </div>
    <!-- / Header -->

    <StepWizard
      class="adventure-sum-page__steps-wizard"
      :isStickNumbersToLeftEdge="true"
      @slideChange="() => {}"
      :titles="[{ title: 'It\'s as simple as&hellip;' }, { title: 'Choose which adventure you would like to add to your site!' }]"
    >
      <template #[0]="{ goToNextSlide }">
        <!-- Initial guide -->
        <div class="initial-guide adventure-sum-page__initial-guide">
          <!-- Preview snippet -->
          <div class="preview-snippet initial-guide__preview-snippet" v-for="(example, index) in exampleSnippets" :key="example.name" :style="{ order: index * 2 }">
            <h5 class="global-h5 preview-snippet__title">{{ example.name }}</h5>
            <div class="preview-snippet__preview-frame">
              <img class="preview-snippet__preview-img" :src="`${contentBaseUri}${exampleSnippetImagePath}${example.image}`" :alt="example.name" />
            </div>
          </div>
          <!-- / Preview snippet -->
          <div class="initial-guide__operator" v-for="(operator, index) in ['+', '=']" :key="operator" :style="{ order: index * 3 }">{{ operator }}</div>
        </div>
        <!-- / Initial guide -->

        <SrpButton class="adventure-sum-page__next-button" color="orange" @click="goToNextSlide">
          Next
          <template #iconRight>
            <IconEmbedded name="arrow-bottom_2-5" :size="21" />
          </template>
        </SrpButton>
      </template>
      <template #[1]="{ goToPrevSlide, goToNextSlide }">
        <ul v-if="approvedAdventures?.length" class="adventure-sum-page__adventure-tiles-list">
          <ItineraryTile
            class="adventure-sum-page__adventure-tile"
            v-for="adventure in approvedAdventures"
            :key="adventure.id"
            :openNewWindow="true"
            :itinerary="adventure"
            :allowShortImage="false"
            :disableLink="false"
            :showRemoveButton="false"
            :hideFooter="true"
            :source="null"
            @click.prevent="chooseAdventure(adventure.id)"
          >
            <template #buttons="{ goToViewLink }">
              <div class="tile-button-n-link" style="margin-top: 8px">
                <SrpButton class="tile-button-n-link__button" size="small" @click.prevent="chooseAdventure(adventure.id)">Choose</SrpButton>

                <span class="tile-button-n-link__link" @click.prevent.stop="goToViewLink(undefined, true)">
                  <span class="tile-button-n-link__link-text">View</span>
                  <IconEmbedded class="tile-button-n-link__link-icon" name="external-link_2-5" />
                </span>
              </div>
            </template>
          </ItineraryTile>
        </ul>

        <h3 v-else class="global-h3" style="color: rgba(0, 0, 0, 0.5); font-style: italic; font-weight: 500">No adventures yet</h3>
      </template>
    </StepWizard>

    <SrpModal v-model:isVisible="isWhatNextModalVisible" size="large" isWithScroll>
      <template #header>
        <h1 class="global-h1">Next Steps</h1>
      </template>
      <template #content>
        <WhatNext :selectedAdventureId="selectedAdventureId">
          <template #colorPicker>
            <GlobalAccentColorPicker v-if="approvedAdventures?.length" class="header__color-selector" dropdownPosition="top" />
          </template>
        </WhatNext>
      </template>
    </SrpModal>
  </div>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "AdventureSummary" };</script>

<script setup lang="ts">
import axios from "axios";
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { brandColorHex } from "@/constants";

// Types
import { Itinerary } from "@contracts/itinerary";

// Components
import { ColorPicker } from "vue-accessible-color-picker";
import ItineraryTile from "@components/ItineraryTile.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import StepWizard from "@components/ui/StepWizard.vue";
import WhatNext from "./WhatNext.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import GlobalAccentColorPicker from "@components/GlobalAccentColorPicker.vue";

const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

// Get the itineraries ========================================================
const approvedAdventures = ref<Array<Itinerary>>([]);
const route = useRoute();

async function getItineraries() {
  const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/${route.params.pageId}/approvals/summary`);

  approvedAdventures.value = data.approved;
}

onMounted(getItineraries);

// Example snippets ===========================================================
const exampleSnippetImagePath = "/images/misc/advsumadmin/";
const exampleSnippets = [
  { name: "Choose a Shrpa Adventure", image: "tile2.jpg" },
  { name: "Add a ShortCode to your Site", image: "editor.jpg" },
  { name: "Full Adventures on your Site!", image: "onyoursite4.jpg" },
];

// Toggle What next modal =====================================================
const isWhatNextModalVisible = ref(false);

// Choose adventure ===========================================================
const selectedAdventureId = ref("");
async function chooseAdventure(adventureId) {
  isWhatNextModalVisible.value = true;
  selectedAdventureId.value = adventureId;
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Tile button & link =========================================================
.tile-button-n-link {
  display: flex;
  align-items: center;

  &__button {
    margin-right: 12px;
  }

  &__link {
    position: relative;
    white-space: nowrap;

    &::before {
      content: "";
      width: calc(100% + 12px);
      height: calc(100% + 8px);
      position: absolute;
      inset: 50% auto auto 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__link-text {
    margin-right: 4px;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.3);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
  }

  &__link:hover &__link-text {
    text-decoration: none;
  }

  &__link-icon {
    position: relative;
    top: 2px;
    color: rgba(0, 0, 0, 0.4);
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .tile-button-n-link {
    flex-direction: column;
    align-items: flex-start;

    &__button {
      margin: 0 0 8px 0;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .tile-button-n-link {
    flex-direction: column;
    align-items: flex-start;

    &__button {
      margin: 0 0 8px 0;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .tile-button-n-link {
    flex-direction: column;
    align-items: flex-start;

    &__button {
      margin: 0 0 8px 0;
    }
  }
}

// Header =====================================================================
.header {
  padding-bottom: 23px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

  &__h1 {
    margin-bottom: 16px;
    display: inline-block;
    position: relative;
  }

  &__beta-badge {
    height: 15px;
    padding: 0 4px 1px;
    box-sizing: border-box;
    border: 1px rgba(0, 0, 0, 0.3) solid;
    border-radius: 4px;
    display: block;
    position: absolute;
    inset: -4px -33px auto auto;
    color: rgba(0, 0, 0, 0.5);
    font: 12px/12px sans-serif;
    user-select: none;
  }

  &__second-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -45px; // Ask Kostya to clean this up
    margin-bottom: 15px;
  }

  &__subscript {
    text-wrap: balance;
  }

  &__color-selector {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .header {
    &__second-line {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 0;
    }

    &__subscript {
      margin-bottom: 4px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .header {
    &__beta-badge {
      top: -13px;
      right: 0;
    }

    &__second-line {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 0;
    }

    &__subscript {
      margin-bottom: 4px;
    }
  }
}

// Preview snippet ============================================================
.preview-snippet {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    height: 20px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__preview-frame {
    aspect-ratio: 8/7;
    width: 100%;
    box-sizing: border-box;
    border: 1px #dcdcdc solid;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  &__preview-img {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    object-fit: contain;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .preview-snippet {
    flex-direction: row-reverse;

    &__title {
      flex-grow: 1;
      height: auto;
      margin-bottom: 0;
      justify-content: flex-start;
      text-align: left;
    }

    &__preview-frame {
      width: 100px;
      min-width: 100px;
      margin-right: 15px;
    }
  }
}

// Initial guide ==============================================================
.initial-guide {
  display: flex;
  justify-content: center;
  align-items: center;

  &__preview-snippet {
    width: 280px;
    position: relative;
  }

  &__operator {
    aspect-ratio: 1/1;
    width: 100px;
    min-width: 100px;
    padding-bottom: 5px;
    margin: 16px -15px 0;
    box-sizing: border-box;
    border-radius: 200px;
    color: #118689;
    font:
      500 75px/0 "Quicksand",
      sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
    pointer-events: none;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .initial-guide {
    &__operator {
      width: 70px;
      min-width: 70px;
      padding-bottom: 3px;
      margin-top: 45px;
      font-size: 55px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .initial-guide {
    &__operator {
      width: 50px;
      min-width: 50px;
      padding-bottom: 3px;
      margin-top: 45px;
      font-size: 45px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .initial-guide {
    flex-direction: column;
    align-items: flex-start;

    &__preview-snippet {
      width: 100%;
    }

    &__operator {
      width: 50px;
      min-width: 50px;
      padding-bottom: 3px;
      margin: -15px 0 -15px 27px;
      font-size: 45px;
    }
  }
}

// Adventure summary page =====================================================
.adventure-sum-page {
  width: 1116px;
  margin: 0 auto;
  color: #5b5b5b;
  font: 14px/18px sans-serif;

  &__header {
    position: relative;
    z-index: 1;
  }

  &__steps-wizard {
  }

  &__initial-guide {
    margin-bottom: 28px;
  }

  &__example-snippet {
    width: 229px;
    margin: 0 30px 30px 0;
  }

  &__next-button {
  }

  &__adventure-tiles-list {
    width: calc(100% + 10px);
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    position: relative;
    z-index: 0;
  }

  &__adventure-tile {
    width: calc(100% / 5 - 27px * 4 / 5 - 2px);
    margin: 0 27px 27px 0;
    box-sizing: border-box;
    border-radius: 8px;

    &:nth-child(5n) {
      margin-right: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .adventure-sum-page {
    width: 945px;

    &__adventure-tile {
      width: calc(100% / 4 - 27px * 3 / 4 - 3px);

      &:nth-child(5n) {
        margin-right: 27px;
      }

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .adventure-sum-page {
    width: 822px;

    &__example-snippet {
      width: 200px;
    }

    &__adventure-tile {
      width: calc(100% / 4 - 24px * 3 / 4 - 3px);
      margin: 0 24px 24px 0;

      &:nth-child(5n) {
        margin-right: 24px;
      }

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .adventure-sum-page {
    width: 762px;

    &__example-snippet {
      width: 200px;
    }

    &__adventure-tile {
      width: calc(100% / 4 - 22px * 3 / 4 - 3px);
      margin: 0 22px 22px 0;

      &:nth-child(5n) {
        margin-right: 22px;
      }

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .adventure-sum-page {
    width: 502px;

    &__example-snippet {
      width: 170px;
      margin-right: 22px;
    }

    &__adventure-tile {
      width: calc(100% / 3 - 18px * 2 / 3 - 3px);
      margin: 0 18px 18px 0;

      &:nth-child(5n) {
        margin-right: 18px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .adventure-sum-page {
    width: 100%;

    &__examples-list {
    }

    &__example-snippet {
      width: 130px;
      margin-right: 17px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    &__adventure-tiles-list {
      width: 100%;
    }

    &__adventure-tile {
      width: calc(100% / 2 - 17px * 1 / 2 - 3px);
      margin: 0 17px 17px 0;

      &:nth-child(5n) {
        margin-right: 17px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>
