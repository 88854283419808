<template>
  <div>
    <div v-if="userProfileStore.getViewingUserProfile?.collabPreferences?.isCollabEligible" class="paid-creator-message">
      <h2 class="global-h2">Thank you for your interest!</h2>
      <p>Spotlight is designed for organizations and is not available for individual creator accounts.</p>
      <p>
        If you have any questions or need assistance, reach out anytime to <b><CopyTextNew>support@shrpa.com</CopyTextNew></b>
      </p>
      <SrpButton class="creator-dashboard-button" :to="{ name: 'CreatorDashboard' }"> Go to Creator Dashboard </SrpButton>
    </div>
    <div class="org-init-data-form" v-else>
      <div class="org-init-data-form__column">
        <!--<h2 class="global-h2 org-init-data-form__column-title">About Your Firm</h2>-->
        <!-- Title & input snippet -->
        <div class="title-and-input-snippet org-init-data-form__title-and-input-snippet">
          <h5 class="global-h5 title-and-input-snippet__title">Organization Name</h5>
          <input class="global-text-input title-and-input-snippet__input" type="text" v-model="orgInitData.orgName" />
          <!-- ID snippet -->
          <div v-if="orgInitData.uniqueId && !isUniqueIdWasManuallyModified" class="id-snippet title-and-input-snippet__id-snippet">
            <div class="id-snippet__title">ID:</div>
            <div class="id-snippet__id-itself">{{ orgInitData.uniqueId }}</div>
            <LinkWithIcon class="id-snippet__change-link" iconInset="0 0 0 3px" isDottedUnderline @click="editId" color="teal">
              <template #icon><IconEmbedded name="pencil_2" :size="16" color="rgba(17, 134, 137, 1)" /></template>
              <span>change</span>
            </LinkWithIcon>
          </div>
          <!-- / ID snippet -->
        </div>
        <!-- / Title & input snippet -->

        <!-- Title & input snippet -->
        <div v-if="isUniqueIdWasManuallyModified" class="title-and-input-snippet org-init-data-form__title-and-input-snippet">
          <h5 class="global-h5 title-and-input-snippet__title">Unique ID</h5>
          <input class="global-text-input title-and-input-snippet__input" type="text" v-model="orgInitDataUniqueId" ref="domRefIdInput" />
        </div>
        <!-- / Title & input snippet -->

        <!-- Title & input snippet -->
        <div class="title-and-input-snippet org-init-data-form__title-and-input-snippet">
          <h5 class="global-h5 title-and-input-snippet__title">Website <span class="title-and-input-snippet__optional-mark">(optional)</span></h5>
          <input class="global-text-input title-and-input-snippet__input" type="text" v-model="orgInitData.website" />
        </div>
        <!-- / Title & input snippet -->

        <!-- Title & input snippet -->
        <div class="title-and-input-snippet org-init-data-form__title-and-input-snippet">
          <h5 class="global-h5 title-and-input-snippet__title">City</h5>
          <input class="global-text-input title-and-input-snippet__input" type="text" v-model="orgInitData.city" />
        </div>
        <!-- / Title & input snippet -->

        <!-- Title & input snippet -->
        <div class="title-and-input-snippet org-init-data-form__title-and-input-snippet">
          <h5 class="global-h5 title-and-input-snippet__title">State</h5>
          <select class="global-text-input title-and-input-snippet__input" @change="$event => (orgInitData.state = ($event.target as HTMLSelectElement).value)">
            <option selected disabled hidden>&lt;no state set&gt;</option>
            <option v-for="state in allStates.filter(s => s.name.toLowerCase() !== 'rvers')" :key="state.name" :value="state.abbrev">
              {{ state.name }}
            </option>
          </select>
        </div>
        <!-- / Title & input snippet -->

        <!-- Title & input snippet -->
        <div class="title-and-input-snippet org-init-data-form__title-and-input-snippet">
          <h5 class="global-h5 title-and-input-snippet__title">Country</h5>
          <select class="global-text-input title-and-input-snippet__input" v-model="orgInitData.country">
            <option selected disabled hidden>&lt;no country set&gt;</option>
            <option v-for="country in allCountries" :key="country" :value="country">
              {{ country }}
            </option>
          </select>
        </div>
        <!-- / Title & input snippet -->
      </div>

      <div class="org-init-data-form__column">
        <!--<h2 class="global-h2 org-init-data-form__column-title">About You</h2>-->

        <!-- Title & input snippet -->
        <div class="title-and-input-snippet org-init-data-form__title-and-input-snippet">
          <h5 class="global-h5 title-and-input-snippet__title">Your Name</h5>
          <input class="global-text-input title-and-input-snippet__input" type="text" v-model="orgInitData.usersName" />
        </div>
        <!-- / Title & input snippet -->

        <!-- Title & input snippet -->
        <div class="title-and-input-snippet org-init-data-form__title-and-input-snippet org-init-data-form__title-and-input-snippet--bigger-margin-bottom">
          <h5 class="global-h5 title-and-input-snippet__title">Email</h5>
          <div class="title-and-input-snippet__text">{{ userProfileStore.getViewingUserProfile?.email }}</div>
        </div>
        <!-- / Title & input snippet -->

        <!-- Title & input snippet -->
        <div class="title-and-input-snippet org-init-data-form__title-and-input-snippet">
          <h5 class="global-h5 title-and-input-snippet__title">How did you hear about us? <span class="title-and-input-snippet__optional-mark">(optional)</span></h5>

          <select class="global-select title-and-input-snippet__input" @change="$event => (orgInitData.heardAbout = ($event.target as HTMLSelectElement).value)">
            <option selected disabled hidden>Select an option</option>
            <option value="Google">Google</option>
            <option value="Email">Email</option>
            <option value="Friend">Friend</option>
            <option value="Conference">Conference</option>
            <option value="Other source">Other source</option>
          </select>
        </div>
        <!-- / Title & input snippet -->
      </div>

      <div class="org-init-data-form__footer">
        <div class="global-form-errors-list org-init-data-form__errors-list">
          <ul class="global-form-errors-list__list">
            <li class="global-form-errors-list__li" v-for="error in errorsList" :key="error">
              {{ error }}
            </li>
          </ul>
        </div>

        <SrpButton class="org-init-data-form__confirm-button" :isDisabled="isSaving" size="big" @click="save">Finish</SrpButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
import { ref, watch, nextTick, computed } from "vue";
import { allStates } from "@helpers/GetStateName";
import { allCountries } from "@helpers/AllCountries";
import { getGlobalRemoteLogger } from "@/helpers/RemoteLogger";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";
const userProfileStore = useUserProfileStore();

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import CopyTextNew from "@components/CopyTextNew.vue";

// All inputs data ============================================================
interface CreateSpotlightCustomerInputs {
  // org name
  orgName: string;
  uniqueId: string;

  website: string;

  city: string;
  state: string;
  country: string;

  usersName: string;
  email: string;

  heardAbout: string;
}

const orgInitData = ref<CreateSpotlightCustomerInputs>({
  orgName: "",
  uniqueId: "",

  website: "",

  city: "",
  state: "",
  country: "USA",

  usersName: "",
  email: "",

  heardAbout: "",
});

// Clean the UniqueID =========================================================
function cleanTheId(newValue: string): string {
  return newValue.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
}

const orgInitDataUniqueId = computed({
  get() {
    return orgInitData.value.uniqueId;
  },
  set(newValue) {
    orgInitData.value.uniqueId = newValue; // in this way we forcibly rerender the input when a user types forbidden symbols (
    orgInitData.value.uniqueId = cleanTheId(newValue);
  },
});

// Set the Unique ID ==========================================================
const isUniqueIdWasManuallyModified = ref<boolean>(false);
const domRefIdInput = ref<HTMLInputElement | null>(null);

async function editId() {
  isUniqueIdWasManuallyModified.value = true;
  await nextTick();
  domRefIdInput.value?.focus();
}

watch(
  () => orgInitData.value.orgName,
  () => {
    if (!isUniqueIdWasManuallyModified.value) {
      orgInitData.value.uniqueId = cleanTheId(orgInitData.value.orgName);
    }
  }
);

// Check if ID availability ===================================================
async function checkIfIDAvailable(uniqueId: string): Promise<boolean> {
  const uri = `${import.meta.env.VITE_API_URL}/spotlight/customers/id-available?uniqueId=${uniqueId}`;
  const { data } = await axios.get<boolean>(uri);
  return data;
}

// Form validation ============================================================
const errorsList = ref<Array<string>>([]);

const orgNameMinLength = 5;

async function validateForm(): Promise<boolean> {
  errorsList.value = [];

  // Empty value check
  if (!orgInitData.value.orgName) errorsList.value.push('Please fill the "Organization Name" field');
  if (!orgInitData.value.uniqueId) errorsList.value.push('Please fill the "Unique Identifier" field');

  if (!orgInitData.value.city) errorsList.value.push('Please fill the "City" field');
  if (!orgInitData.value.state) errorsList.value.push('Please fill the "State" field');
  if (!orgInitData.value.country) errorsList.value.push('Please fill the "Country" field');

  if (!orgInitData.value.usersName) errorsList.value.push('Please fill the "Your Name" field');

  // Org name length
  if (orgInitData.value.orgName && orgInitData.value.orgName.length < orgNameMinLength) {
    errorsList.value.push(`Organization Name should be longer than ${orgNameMinLength - 1} characters`);
  }

  // Check ID availability
  if (orgInitData.value.uniqueId && !(await checkIfIDAvailable(orgInitData.value.uniqueId))) {
    errorsList.value.push(`This ID is already taken. Change it please`);
  }

  var isValid = errorsList.value.length === 0;
  if (!isValid) {
    getGlobalRemoteLogger().error(`Spotlight Validation failed: ${errorsList.value.length}`, false, false, {
      errors: errorsList.value,
    });
  }
  return isValid;
}

watch(
  orgInitData,
  () => {
    errorsList.value = [];
  },
  { deep: true }
);

// Save =======================================================================
const isSaving = ref<boolean>(false);
async function save(): Promise<void> {
  if (!(await validateForm())) {
    return;
  }

  isSaving.value = true;

  await axios.post("/api/spotlight/customers", orgInitData.value, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  getGlobalRemoteLogger().info("Spotlight Organization Created! {id}", true, true, {
    id: orgInitData.value.uniqueId,
  });

  // Force refresh the page (quick way to reset the state, could possibly improve this if we reload the right profile and org context)
  // Send the user to /spotlight but in a way that forces a browser refresh
  window.location.href = "/spotlight";
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// ID snippet =================================================================
.id-snippet {
  display: flex;
  align-items: center;

  &__title {
    color: rgba(0, 0, 0, 1);
  }

  &__id-itself {
  }

  &__change-link {
  }
}

// Title & input snippet ======================================================
.title-and-input-snippet {
  gap: 5px 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__title {
  }

  &__optional-mark {
    color: rgba(160, 160, 160, 1);
    font-weight: 500;
  }

  &__text {
  }

  &__edit-link {
  }

  &__input {
    width: 100%;
  }

  &__id-snippet {
  }
}

// Organization init data form ================================================
.org-init-data-form {
  gap: 27px 30px;
  padding: 25px;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  color: rgba(91, 91, 91, 1);
  font: 14px/18px sans-serif;
  background: rgba(243, 243, 243, 1);

  &__column {
    width: calc(50% - 15px);
    gap: 22px;
    display: flex;
    flex-direction: column;
  }

  &__column-title {
    margin-bottom: 7px;
  }

  &__title-and-input-snippet {
    &--bigger-margin-bottom {
      margin-bottom: 10px;
    }
  }

  &__footer {
    width: 100%;
    padding-top: 22px;
    border-top: 1px rgba(0, 0, 0, 0.1) solid;
    display: flex;
    justify-content: space-between;
  }

  &__errors-list {
    flex-grow: 1;
  }

  &__confirm-button {
    justify-self: flex-end;
  }
}

.paid-creator-message {
  text-align: center;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    color: #333;
    margin-bottom: 15px;
  }

  p {
    color: #666;
    margin-bottom: 10px;
    line-height: 1.5;
  }
}

// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .org-init-data-form {
    gap: 45px 30px;

    &__column {
      width: 100%;
    }

    &__footer {
      gap: 20px 0;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
</style>
