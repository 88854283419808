<template>
  <SrpModal :isVisible="isVisible" @close="closeClick" size="small" :isClosable="false" maxHeight="calc(100dvh - 30px)" isWithScroll :isContentPaddings="false">
    <template #content>
      <div id="login">
        <img class="shrpa-logo" src="https://cdn.shrpa.com/images/shrpa-full-color.png" alt="Shrpa logo" />
        <div name="close" class="big x icon" color="grey" @click="closeClick()" style="cursor: pointer; float: right"></div>

        <LoginModalErrorDisplay :email="email" v-if="errorState !== false" :error-state="errorState" @click:back="errorState = false" />

        <template v-else-if="showLoginProviders === false">
          <p class="login-heading">Enter your email</p>
          <input class="email-input" type="email" v-model="email" placeholder="user@example.com" @keyup.enter="emailEnteredClick(true)" ref="emailInput" />
          <div style="display: flex; align-items: center">
            <button @click="emailEnteredClick(true)" :disabled="showSpinner" class="ui primary button">Next</button>
            <!--Loader UI-->
            <span v-if="showSpinner" class="ui active inline loader" style="margin-left: 8px"></span>
          </div>

          <div v-if="invalidEmail" class="ui error message">
            <div class="header">Please enter a valid email address.</div>
          </div>

          <div v-if="!existingAccountNotFoundError && !createAccountAlreadyExistsError" style="height: 30px"></div>

          <div v-if="existingAccountNotFoundError" class="ui orange message" style="margin-bottom: 10px">
            <div class="header">
              We couldn't find {{ email }}
              <div style="margin-top: 10px; margin-bottom: 10px">Please verify that's what you previously logged in with.</div>
              <span @click="newUserClick(true)" class="link">Or Create an Account</span>
            </div>
          </div>
          <div v-if="createAccountAlreadyExistsError" class="ui orange message" style="margin-bottom: 10px">
            <div class="header">
              That email is already in use.
              <div style="margin-top: 10px">Did you mean to <span @click="existingUserClick(true)" class="link">Login</span>?</div>
            </div>
          </div>

          <p class="login-heading" style="margin-top: 0; margin-bottom: 0">
            {{ isInLoginFlow ? "New to Shrpa?" : "Already a User?" }}
          </p>
          <p class="login-heading link" @click="alternateActionClick()" style="margin: 0 auto 30px; color: #058587">
            {{ isInLoginFlow ? "Create an Account" : "Log in" }}
          </p>
        </template>

        <template v-else>
          <div class="login-style" style="padding: 25px 0 50px">
            <!--NOTE! The param to login() must be the identity provider connection name in Auth0-->
            <div class="oauth-button oauth-button--google" @click="() => login('google-oauth2')">
              <IconEmbedded class="oauth-button__icon" name="google" />
              {{ signInUpText + " with Google" }}
            </div>
            <div class="oauth-button oauth-button--facebook" @click="() => login('facebook')">
              <IconEmbedded class="oauth-button__icon" name="facebook" />
              Continue with Facebook
            </div>
            <div class="separator">
              <hr class="line" />
              <span class="or-text">OR</span>
              <hr class="line" />
            </div>
            <!--null as the login param sends the user to the Auth0 login screen-->
            <div class="oauth-button oauth-button--email" @click="() => login(null)">{{ signInUpText }} with email/password</div>
            <!--<div basic social="facebook" content="Sign in with Microsoft" icon="microsoft" @click="login('windowslive')" />
          <div social="facebook" content="Sign in with Apple" icon="apple" style="background: black" @click="login('apple')" />-->
          </div>
          <!--          <p class="custom-login-description"></p>-->
          <!--Old UI that de-prioritized Email login, which caused issues with our customers<p @click="login(null)" class="custom-login-link">{{ signInUpText }} with email instead</p>-->
        </template>

        <div class="questions-div">Questions? Email <CopyText /></div>
      </div>
    </template>
  </SrpModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import { MetricSender } from "@helpers/MetricSender";
import Loader from "@components/Loader/Loader.vue";
import CopyText from "@components/CopyText.vue";
import { inject } from "vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import LoginModalErrorDisplay, { type ErrorState } from "./LoginModal/LoginModalErrorDisplay.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

export default defineComponent({
  name: "LoginModal",

  components: {
    IconEmbedded,
    SrpModal,
    SrpButton,
    Loader,
    LoginModalErrorDisplay,
    CopyText,
  },

  props: {
    isVisible: { type: Boolean, default: false },
    defaultEmail: { type: String, default: null, required: false },
    startWithNewUserFlow: { type: Boolean, default: false, required: false },
  },

  data() {
    return {
      globalRemoteLogger: inject("globalRemoteLogger") as any,
      email: null as string | null,

      errorState: false as ErrorState | false,

      isInLoginFlow: true, // Defaulting to login flow
      // States/Steps: Ask if New or Existing, then Collect Email, (if create) then Show Login Providers
      showLoginProviders: false,
      existingAccountNotFoundError: false,
      createAccountAlreadyExistsError: false,
      invalidEmail: false,

      showSpinner: false,
      authProviderForEnteredEmail: null,
      authOverrideForEnteredEmail: null,
    };
  },

  computed: {
    isEmbeddedInstagramBrowser(): boolean {
      // allow for testing in the browser using a query parameter called "instagramBrowser"
      return navigator.userAgent.includes("Instagram") || "instagramBrowser" in this.$route.query;
    },
    signInUpText(): string {
      if (this.isInLoginFlow === false) {
        return "Sign up";
      }
      return "Log in";
    },
  },

  watch: {
    email(to, from) {
      this.emailChanged();
    },
    isVisible(to, from) {
      if (to) {
        // Focus the email input
        this.$nextTick(() => {
          // @ts-ignore
          this.$refs.emailInput?.focus();
        });
      }
    },
  },

  mounted() {
    if (this.startWithNewUserFlow) {
      this.isInLoginFlow = false;
    }

    if (this.defaultEmail) {
      this.email = this.defaultEmail;
      // Since we have an email kickstart them down the auth path (without showing error messages, just send them down the correct path)
      // Note: This is just used for the customer admin invite flow, and is a bit brittle.
      // We should consider reworking or creating a separate component/path for the "new admin login flow"
      this.emailEnteredClick(false);
    }
  },

  methods: {
    async alternateActionClick() {
      if (this.isInLoginFlow) {
        this.newUserClick(false);
      } else {
        this.existingUserClick(false);
      }
    },
    async newUserClick(alreadyHaveEmail: boolean) {
      this.isInLoginFlow = false;
      if (alreadyHaveEmail) this.showLoginProviders = true;
      else {
        // Fire the emailEntered flow here if there's a valid email...
        if (this.currentEmailIsValid()) {
          await this.emailEnteredClick(true);
        }
      }
    },
    existingUserClick(alreadyHaveEmail: boolean) {
      this.isInLoginFlow = true;
      if (alreadyHaveEmail) this.login(this.authProviderForEnteredEmail, this.authOverrideForEnteredEmail);
    },
    closeClick() {
      this.$emit("close");
      this.email = null;
      this.showLoginProviders = false;
      this.createAccountAlreadyExistsError = false;
      this.existingAccountNotFoundError = false;
      this.invalidEmail = false;
      this.errorState = false;
    },
    emailChanged() {
      this.createAccountAlreadyExistsError = false;
      this.existingAccountNotFoundError = false;
      this.invalidEmail = false;
    },
    currentEmailIsValid(): boolean {
      return this.email && this.email.includes("@") && this.email.includes(".");
    },
    async emailEnteredClick(showWrongPathMessages: boolean) {
      if (!this.currentEmailIsValid()) {
        this.invalidEmail = true;
        return;
      }
      this.invalidEmail = false;
      this.globalRemoteLogger.info(`LoginModal EmailEnteredClick: email=${this.email}`, true);

      this.showSpinner = true;
      this.createAccountAlreadyExistsError = false;
      this.existingAccountNotFoundError = false;
      const uri = `${import.meta.env.VITE_API_URL}/login/check?email=${encodeURIComponent(this.email.trim())}`;
      const result = await axios.get(uri);
      let redirectingUserToLoginProvider = false;
      // Existing Account
      if (result.data) {
        // If they intend to login (or we're in the invite flow), move them along
        if (this.isInLoginFlow || !showWrongPathMessages) {
          this.isInLoginFlow = true;
          redirectingUserToLoginProvider = true;
          this.login(result.data.p, result.data.o);
        } else {
          // Otherwise account exists in the create flow, show an error
          this.authProviderForEnteredEmail = result.data.p;
          this.authOverrideForEnteredEmail = result.data.o;
          this.createAccountAlreadyExistsError = true;
          this.globalRemoteLogger.info(`LoginModal AccountAlreadyExists ShouldLogin: email=${this.email}, authProvider=${this.authProviderForEnteredEmail}`, true);
        }
        this.showSpinner = false;
      }
      // Does NOT exist
      else {
        // If they intend to create an account (or we're in the invite flow), move them along
        if (this.isInLoginFlow === false || !showWrongPathMessages) {
          this.isInLoginFlow = false;
          this.showLoginProviders = true;
        } else {
          // The account doesn't exist in the login flow, show an error
          this.existingAccountNotFoundError = true;
          this.globalRemoteLogger.info(`LoginModal AccountDoesNotExist ShouldCreate: email=${this.email}, authProvider=${this.authProviderForEnteredEmail}`, true);
        }
      }

      if (!redirectingUserToLoginProvider) this.showSpinner = false;
    },
    getErrorState(authProvider: string | null): ErrorState | false {
      if (authProvider === "google-oauth2" && this.isEmbeddedInstagramBrowser) {
        this.globalRemoteLogger.info(`LoginModal GoogleUnsupportedInInstagram: email=${this.email}`, true);
        return "google-unsupported-in-instagram";
      }
      return false;
    },
    login(authProvider: string | null, authOverride?: string | null) {
      const authProviderErrorState = this.getErrorState(authProvider);
      if (authProviderErrorState) {
        this.errorState = authProviderErrorState;
        return;
      }
      const createAccount = this.isInLoginFlow === false;
      const redirectPath = window.location.pathname + window.location.search;
      sessionStorage.setItem("redirectPath", redirectPath);
      // Remote log with waitForSend=true here since we're about to redirect
      this.globalRemoteLogger.info(
        `LoginModal.Login: email=${this.email}, authProvider=${authProvider}, createAccount=${createAccount}, emailAuthOverride=${authOverride}, redirectPath=${redirectPath}`,
        true,
        true
      );
      MetricSender.login("navbar");
      // targetUrl is Deprecated now that we use the AuthLanding?
      // Set the targetUrl as state so the user gets redirected to where they were after they come back from login
      let targetUrl = window.location.href.replace(window.location.origin, "");
      let emailHint = this.email;
      if (authOverride?.length > 0) {
        emailHint = authOverride;
      }
      // @ts-ignore
      this.globalAuth.loginWithRedirect(
        {
          appState: {
            targetUrl: targetUrl,
          },
        },
        authProvider,
        emailHint,
        createAccount
      );
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/variables.scss";

#login-wrapper {
  width: 100%;
  height: 100%;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  position: fixed;
  inset: 0 auto auto 0;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.5);
}

#login {
  width: 450px;
  max-width: 100%;
  padding: 10px 0 0 13px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  text-align: center;
  font-family: "Quicksand", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}
.shrpa-logo {
  width: 180px;
  max-width: calc(100% - 30px);
  margin: 10px 0 10px;
}

.email-input {
  max-width: calc(100% - 30px);
  margin-bottom: 15px;
}

// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .shrpa-logo {
    width: 130px;
    margin-bottom: 0;
  }
}

.login-heading {
  /*color: #058587;*/
  /*text-align: center;*/
  font-size: 18px;
  line-height: 1.5;
  font-weight: bold;
  margin-top: 5px;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.questions-div {
  width: 100%;
  height: 80px;
  padding-top: 28px;
  margin-top: 56px;
  margin-bottom: 15px;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  font-size: 1.07em;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 6px;
    position: absolute;
    inset: 0 auto auto 50%;
    z-index: -1;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.05);
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
}

@media screen and (max-width: 420px) {
  .questions-div {
    font-size: 1em;
  }
}

/* OLD UI styles */
.login-style {
  display: flex;
  flex-direction: column;
}
.custom-login-description {
  margin-top: 15rem;
  margin-bottom: 0.5rem;
}
.custom-login-link {
  color: #058587;
  text-align: center;
  font-size: 18px;
  line-height: 1.5;
  font-weight: bold;
  cursor: pointer;
}
.facebook.button {
  margin: 1rem;
  font-weight: 700;
}

/* Oauth button =============================================================== */
.oauth-button {
  width: 250px;
  height: 35px;
  margin-bottom: 15px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
}

.oauth-button:hover {
  opacity: 0.9;
}

.oauth-button--google {
  background: #1e70bf;
}
.oauth-button--facebook {
  background: #3b5998;
}

.oauth-button--email {
  margin-top: 15px;
  background: $brand-color;
}

.oauth-button__icon {
  width: 24px;
  height: 24px;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  font-size: 24px;
  line-height: 24px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.line {
  flex-grow: 1;
  border: none;
  border-top: 1px solid #ccc;
  margin: 0 10px;
}

.or-text {
  padding: 0 10px;
}
</style>
