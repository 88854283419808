<template>
  <div>
    <Loader v-if="isLoading" style="position: absolute"></Loader>
    <div v-if="page" class="ui form">
      <h1 style="display: inline; margin-right: 25px">Destination Page</h1>
      <div class="ui stackable grid">
        <div class="nine wide column">
          <div class="field">
            <label class="cms-section-title">Name</label>
            <p class="cms">The destination name you would like displayed</p>
            <input type="text" v-model="page.title" />
          </div>

          <div v-if="!isHotel" class="field">
            <label class="cms-section-title">Description</label>
            <p class="cms">A short description of your destination</p>
            <textarea v-model="page.description" autocapitalize="sentences"></textarea>
          </div>

          <div v-if="!isHotel" class="field">
            <label class="cms-section-title">Website</label>
            <p class="cms">The url of your website</p>
            <input type="text" v-model="page.website" />
          </div>
          <div v-if="!isHotel" class="field">
            <label class="cms-section-title">Website Display Text</label>
            <p class="cms">(Optional) If you want to show this text instead of your website url</p>
            <input type="text" v-model="page.websiteText" />
          </div>

          <SrpDetailsSummary isOpened style="margin-top: 25px">
            <template #heading>
              <label class="cms-section-title expandable-title">Social Media Handles</label>
            </template>
            <template #details>
              <p class="cms" style="margin-top: 0">Shown on your destination page and helps creators tag your handle during a creator visit.</p>
              <SocialMediaInputs :socialFields="page" />
            </template>
          </SrpDetailsSummary>
        </div>

        <div class="four wide column right floated">
          <div class="ui form" style="margin-bottom: 22px">
            <div class="field">
              <br />
              <input @change="uploadLogo()" class="inputfile" type="file" id="logoImageInput" accept="image/*" />
              <input type="text" v-model="page.icon" style="display: none" />
              <img :src="`${contentBaseUri}/cms/images/orgs/${page.icon}`" class="ui image profile-image ui raised segment" />

              <br />
              <label for="logoImageInput">
                <SrpButton size="small" fill="outlined" style="width: 100%">
                  <i class="ui upload icon"></i>
                  Upload Logo
                </SrpButton>
              </label>
            </div>
          </div>

          <div style="margin-bottom: 14px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>

          <div style="margin-bottom: 17px; display: flex; align-items: center; color: rgba(91, 91, 91, 1); font-family: sans-serif">
            <GlobalAccentColorPicker style="margin: 0 10px 4px 0; z-index: 10" dropdownPosition="top" @change="newColorHex => (page.accentColor = newColorHex)" />
          </div>

          <div style="margin-bottom: 17px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>

          <div>
            <div>
              <SrpButton @click="save" style="margin-right: 12px">Save</SrpButton>
              <SrpButton lang="en" hreflang="en" fill="outlined" @click="saveAndPreview">Preview</SrpButton>
            </div>
            <h3 v-if="saveText">{{ saveText }}</h3>
            <h3 v-if="imageUploading" style="display: inline">Uploading...</h3>
            <div v-if="errors.length" style="color: firebrick; margin-top: 12px">
              <b v-for="error in errors" :key="error">{{ error }}</b>
            </div>
          </div>
        </div>
      </div>

      <SrpDetailsSummary v-if="!page.isSpotlight" isOpened style="margin-top: 25px">
        <template #heading>
          <label class="cms-section-title expandable-title">Boundary</label>
        </template>
        <template #details>
          <p class="cms" style="margin-top: 0">Define the boundary of your destination.</p>
          <!--NOTE: Similar UI in PaidCreatorFields-->
          <div class="travel-boundary-selections">
            <label class="checkbox-card" :class="{ active: page.boundarySelectMode === 'draw' }" @click="setBoundaryMode('draw')">
              <input type="radio" name="frequency" :checked="page.boundarySelectMode === 'draw'" style="width: 13px; margin-right: 8px" />
              <i class="pencil alternate icon"></i> Draw Boundary On Map
            </label>
            <label class="checkbox-card" :class="{ active: page.boundarySelectMode === 'radius' }" @click="setBoundaryMode('radius')">
              <input type="radio" name="frequency" :checked="page.boundarySelectMode === 'radius'" style="width: 13px; margin-right: 8px" />
              <i class="map marker alternate icon"></i> Radius around your Destination
            </label>
            <h3 v-if="boundaryValidationMessage" style="color: red; font-weight: bold; margin-top: 10px">{{ boundaryValidationMessage }}</h3>
          </div>
          <div class="map-top" v-if="page.boundarySelectMode">
            <div>
              <div v-if="page.boundarySelectMode === 'radius'">
                Everything within
                <input type="number" min="10" max="400" step="10" v-model="page.withinMiles" style="width: 70px; height: 25px; display: inline; margin-left: 0.5rem; margin-right: 0.5rem" />
                miles of the map marker is included.
              </div>
              <h4 v-else-if="page.boundarySelectMode === 'draw'">Click on the map to create your boundary!</h4>
            </div>
            <div style="display: flex; align-items: center">
              <h4 v-if="page.boundarySelectMode === 'radius'">The map marker is draggable</h4>
            </div>
          </div>
          <DrawingMap
            style="width: 100%; margin: 0px"
            mapAspectRatio="2/1"
            :lat="page.latitude"
            :lng="page.longitude"
            :inputGeoJson="page.boundaryGeoJson"
            :circleRadiusInMiles="+page.withinMiles"
            :boundarySelectMode="page.boundarySelectMode"
            :onlyAllowDrawingMode="false"
            :isCreatorFlow="false"
            @boundaryChanged="boundaryChanged"
            @markerManuallyMoved="markerManuallyMoved"
          />
        </template>
      </SrpDetailsSummary>

      <div style="margin-top: 35px">
        <SrpButton @click="save" style="margin-right: 10px">Save</SrpButton>
        <SrpButton lang="en" hreflang="en" fill="outlined" @click="saveAndPreview">Preview</SrpButton>
        <h3 v-if="saveText" style="display: inline; margin-left: 10px">{{ saveText }}</h3>
        <div v-if="errors.length" style="color: firebrick; margin-top: 12px">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import Loader from "@components/Loader/Loader.vue";
import { BoundarySelectMode, Page } from "@contracts/pages";
import DrawingMap from "./DrawingMap.vue";
import FileUtils from "@logic/FileUtils";
import SocialMediaInputs from "@components/SocialMediaInputs.vue";
import SocialFieldValidator from "@logic/SocialFieldValidator";
import { inject } from "vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import { buildSquareAroundCenterAsGeoJson } from "@helpers/GeoSpatialHelper";
import { useHead } from "@unhead/vue";
import AdminContext from "@logic/AdminContext";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpDetailsSummary from "@components/ui/SrpDetailsSummary.vue";
import GlobalAccentColorPicker from "@components/GlobalAccentColorPicker.vue";
import InfoIconWithTooltip from "@components/ui/InfoIconWithTooltip.vue";

export default defineComponent({
  name: "CommunityPageAdmin",

  components: {
    InfoIconWithTooltip,
    GlobalAccentColorPicker,
    SrpButton,
    SrpModal,
    Loader,
    DrawingMap,
    SocialMediaInputs,
    IconEmbedded,
    SrpDetailsSummary,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,
      globalRemoteLogger: inject("globalRemoteLogger") as any,

      title: "Destination Page",
      page: null as Page,
      isHotel: false,
      isLoading: true,
      showUnsavedChangesModal: false,
      saveText: null as string | null,
      errors: [] as Array<string>,
      boundaryValidationMessage: null,
      imageUploading: false,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
    };
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    let id = this.$route.params.pageId;
    // @ts-ignore
    await this.loadData(id);
    AdminContext.setOrgInContextFromCustomer(this.page);
  },

  methods: {
    async loadData(identifier: string) {
      let uri = `${import.meta.env.VITE_API_URL}/pages/${identifier}/admin-edit`;
      const response = await axios.get(uri);
      this.page = response.data;
      this.isHotel = this.page.type === "Hotel";
      this.isLoading = false;
      this.title += ` - ${this.page.title}`;
    },
    async save(): Promise<boolean> {
      this.prepForSave();
      this.saveText = "";
      // Validate
      this.errors = [];
      this.boundaryValidationMessage = null;
      var socialFieldValidation = SocialFieldValidator.validateSocialFields(this.page);
      if (!this.page.title) {
        this.errors.push("Name is required");
        return false;
      }
      if (socialFieldValidation[0] === false) {
        this.errors.push(...socialFieldValidation[1]);
        return false;
      } else if (!this.page.boundarySelectMode || this.page.boundarySelectMode.trim().length === 0) {
        let message = "Select a boundary mode";
        this.errors.push(message);
        this.boundaryValidationMessage = message;
        return false;
      } else if (this.page.boundarySelectMode === "draw" && (!this.page.boundaryGeoJson || this.page.boundaryGeoJson.trim().length === 0)) {
        let message = "Click the map to draw your boundary";
        this.errors.push(message);
        this.boundaryValidationMessage = message;
        return false;
      } else {
        // Save it!
        this.globalLog.info("Saving page!");
        let uri = `${import.meta.env.VITE_API_URL}/pages/${this.page.id}`;
        this.saveText = "Saving...";
        const response = await axios.put(uri, this.page);
        this.page = response.data;
        this.saveText = "Saved";
        return true;
      }
    },
    setBoundaryMode(mode: BoundarySelectMode) {
      this.page.boundarySelectMode = mode;
      this.boundaryValidationMessage = null;
      this.errors = [];

      // Default the bounding box if they select draw mode
      // Note: We have similar logic on the creator side in onSelectBoundaryMode
      if (mode === "draw" && !this.page.boundaryGeoJson) {
        if (this.page.latitude && this.page.longitude) {
          var geoJson = buildSquareAroundCenterAsGeoJson(this.page.latitude, this.page.longitude, this.page.withinMiles ?? 10);
          this.page.boundaryGeoJson = geoJson;
          this.globalRemoteLogger.info(`Destination: Defaulting square boundary with centerLat=${this.page.latitude}, centerLng=${this.page.longitude}: ${geoJson}`);
        }
      }
    },
    prepForSave() {
      // Ensure the website starts with http
      if (this.page.website && !this.page.website.startsWith("http")) {
        this.page.website = "https://" + this.page.website;
      }
      if (!this.page.websiteText || this.page.websiteText.trim().length === 0) {
        this.page.websiteText = null;
      }
      SocialFieldValidator.fixSocialFields(this.page);
    },
    boundaryChanged(geoJsonBoundaryString: string, areaInMiles: number) {
      // this.globalLog.info("boundary changed " + geoJsonBoundaryString);
      this.page.boundaryGeoJson = geoJsonBoundaryString;
      this.page.boundaryAreaInMiles = areaInMiles;
    },
    markerManuallyMoved(lat: number, lng: number) {
      this.page.latitude = lat;
      this.page.longitude = lng;
    },
    async saveAndPreview() {
      if (await this.save()) {
        // Removed the old isDirty logic, just saving and navigating
        this.$router.push({ name: "Pages", params: { pageId: this.page.uniqueName } });
      }
    },
    getGoogleMapLatLngLink(): string {
      return `https://www.google.com/maps/search/?api=1&query=${this.page.latitude},${this.page.longitude}`;
    },
    async uploadLogo() {
      // Note: Similar code in Profile.vue
      this.imageUploading = true;
      var uploadInput: any = document.getElementById("logoImageInput");
      if (uploadInput.files.length > 0) {
        var file = uploadInput.files[0];
        var error = FileUtils.isFileSupportedForUpload(file.name, file.type, file.size);
        if (error?.length > 0) {
          this.saveText = error;
        } else {
          var formData = new FormData();
          formData.append("file", file);
          try {
            var result = await axios.post(`${import.meta.env.VITE_API_URL}/images?type=CustomerIcon`, formData);
            this.saveText = null;
            this.page.icon = result.data[0];
          } catch (error) {
            if (error.response?.status === 400) this.saveText = error.response.data;
            else this.saveText = "Error uploading image, please contact support@shrpa.com";
          }
        }

        // Note: Ideally should set the nav icon on partnerInfo in App.vue also...

        this.imageUploading = false;
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/variables.scss";

.expandable-title {
  margin-top: 5px !important;
  margin-left: 3px !important;
}

.map-top {
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
}
.travel-boundary-selections {
  margin-top: 1rem;
}
.checkbox-card {
  width: auto !important;
  padding: 12px 16px;
  margin-right: 1rem !important;
  border: 1px solid rgba(255, 255, 255, 0.15) !important;
  border-radius: 4px;
  display: inline-flex !important;
  background-color: #eaeaea !important;
  cursor: pointer;

  label {
    cursor: pointer;
  }

  &.active {
    background-color: $brand-color-lighter !important;
    border-color: $brand-color-light !important;
    label {
      color: $brand-color !important;
    }
  }
}
</style>
