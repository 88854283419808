<template>
  <component
    v-if="isBadgeVisible"
    :is="globalRoot.orgInContext?.customerId ? 'RouterLink' : 'div'"
    :class="{
      'trial-days-counter': true,
      'trial-days-counter--red': props.paymentStatus === 'PaymentFailed' || props.paymentStatus === 'Cancelled',
    }"
    :to="globalRoot.orgInContext?.customerId ? { name: 'SubscriptionSettings', params: { pageId: globalRoot.orgInContext?.customerId } } : null"
  >
    <template v-if="props.paymentStatus === 'Trial'">
      <template v-if="daysUntilTrialEnd > 0 && daysUntilTrialEnd < howManyDaysShouldLeftToShowBadge">
        <IconEmbedded class="trial-days-counter__icon" name="clock_3" :size="15" color="rgba(34, 52, 29, 1)" />
        <span class="trial-days-counter__text">{{ daysUntilTrialEnd }} days left in trial</span>
      </template>
      <template v-else>
        <IconEmbedded class="trial-days-counter__icon" name="clock_3" :size="15" color="rgba(34, 52, 29, 1)" />
        <span class="trial-days-counter__text">Subscribe today</span>
      </template>
    </template>

    <template v-else-if="props.paymentStatus === 'Cancelled'">
      <IconEmbedded class="trial-days-counter__icon" name="warning_3" :size="15" color="rgba(65, 56, 6, 1)" />
      <span class="trial-days-counter__text">Reactivate today</span>
    </template>

    <template v-else-if="props.paymentStatus === 'PaymentFailed'">
      <IconEmbedded class="trial-days-counter__icon" name="warning_3" :size="15" color="rgba(71, 42, 37, 1)" />
      <span class="trial-days-counter__text">Update Payment Method</span>
    </template>
  </component>
</template>

<script setup lang="ts">
import AdminContext from "@logic/AdminContext";
import { ref, onMounted, computed } from "vue";
import moment from "moment/moment";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Types
import { CustomerPaymentStatus } from "@contracts/partnerNavInfo";

const props = withDefaults(
  defineProps<{
    trialEndDate: string | null;
    paymentStatus: CustomerPaymentStatus | null;
  }>(),
  {
    trialEndDate: null,
    paymentStatus: null,
  }
);

const howManyDaysShouldLeftToShowBadge = 15;

// Is badge visible ===========================================================
const isBadgeVisible = computed<boolean>(() => {
  if (props.paymentStatus === "Active") {
    return false;
  } else {
    return ["Trial", "Cancelled", "PaymentFailed"].includes(props.paymentStatus);
  }
});

const daysUntilTrialEnd = computed<number | null>(() => {
  if (!props.trialEndDate) {
    return null;
  }
  const trialEndDate = moment(props.trialEndDate);
  const today = moment();
  return trialEndDate.diff(today, "days");
});
</script>

<style scoped lang="scss">
// Trial days counter =========================================================
.trial-days-counter {
  padding: 0 19px 0 15px;
  height: 27px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  color: rgba(34, 52, 29, 1);
  font:
    600 15px/15px "Quicksand",
    sans-serif;
  cursor: pointer;
  user-select: none;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 100px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: rgba(213, 236, 206, 1);
    transition:
      width 0.07s ease-in-out,
      left 0.07s ease-in-out;
  }
  &:hover::before {
    width: calc(100% + 6px);
    left: -3px;
  }

  &--yellow {
    color: rgba(65, 56, 6, 1);

    &::before {
      background: rgba(240, 231, 184, 1);
    }
  }

  &--red {
    color: rgba(71, 42, 37, 1);

    &::before {
      background: rgba(248, 213, 207, 1);
    }
  }

  &__icon {
    margin-right: 4px;
  }

  &__text {
    padding-bottom: 1px;
  }
  &:hover &__text {
    text-decoration: underline;
    text-decoration-color: rgba(34, 52, 29, 0.3);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
  }
}
</style>
