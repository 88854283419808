// Gets and sets the communityId in context for administration

import axios from "axios";
import { Page } from "@contracts/pages";
import { CustomerPaymentStatus, PartnerNavInfo } from "@contracts/partnerNavInfo";
import { UserProfile } from "@contracts/userProfile";
import Logger from "@helpers/Logger";
import { RouteHelper } from "@helpers/RouteHelper";
import { useUserProfileStore } from "@stores/userProfileStore";
import { setChurnZeroContactAndTrackLogInForCustomerAdmin } from "./ChurnZero";

// NOTE: Currently sets per session (not across sessions).  Could make stickier if needed.
export default {
  // Checks if the current user is an admin of the passed community.
  // Note: Superusers always return true
  isAdminOf(communityId: string, profile: UserProfile | null, shouldLog = false, excludeSuperUser = false) {
    if (shouldLog) Logger.info(`isAdminOf: customerIdInContext=${communityId}, superUser=${profile?.isSuperuser} adminOf=${profile?.adminOf.join(",")}`);
    if (profile?.isSuperuser === true && !excludeSuperUser) {
      return true;
    }
    if (profile?.adminOf.some(id => id.toLowerCase() === communityId?.toLowerCase())) {
      return true;
    }
    return false;
  },

  // rename this to *FromCustomer
  setOrgInContextFromCustomer(customer: Page) {
    // NOTE!! Setting topNavBackLink as null since the pages that set based on this send the user
    // back to the community admin, which pivots off whether the topNavBackLink field is set
    return this.setOrgInContext(
      null,
      customer?.partnerNav?.topNavLogoUri,
      customer?.uniqueName,
      customer?.id,
      customer?.type,
      customer?.title,
      customer.immutableId,
      customer.isDisabled,
      customer.isSpotlight,
      customer.enableCreateAdventures,
      customer.trialEndDate,
      customer.paymentStatus
    );
  },

  setOrgInContext(
    topNavBackLink: string | null,
    topNavLogoUri: string,
    uniqueName: string,
    customerId: string,
    type: string,
    orgName: string,
    immutableId: string,
    isDisabled: boolean,
    isSpotlight: boolean,
    enableCreateAdventures: boolean,
    trialEndDate: string | null,
    paymentStatus: CustomerPaymentStatus | null
  ): PartnerNavInfo {
    var partner: PartnerNavInfo = {
      siteUri: topNavBackLink,
      logoUri: topNavLogoUri,
      uniqueName,
      customerId,
      immutableId,
      type,
      name: orgName,
      isDisabled,
      isSpotlight,
      enableCreateAdventures,
      trialEndDate,
      paymentStatus,
    };

    Logger.info(
      `setOrgInContext: uniqueName=${partner.uniqueName}, customerId=${partner.customerId}, type=${partner.type}, backLink=${partner.siteUri}, logoUri=${partner.logoUri}, name=${partner.name}`
    );
    // Store it in session so it stays if they navigate around
    sessionStorage.setItem("shrpaPartnerv2", JSON.stringify(partner));

    // currently, setOrgInContext is called when a session storage admin context
    // isn't available OR when an admin changes which org they are viewing.
    setChurnZeroContactAndTrackLogInForCustomerAdmin(customerId, immutableId);

    return partner;
  },

  clearOrgInContext() {
    Logger.info(`Clearing org in context`);
    sessionStorage.removeItem("shrpaPartnerv2");
  },

  // Checks for incoming PartnerId on the uri (ex. when a user comes from a customer's site)
  async defaultOrgInContext(): Promise<PartnerNavInfo | null> {
    try {
      // If the user navigated in from a partner site this code will show an icon and link back in the navigation
      const partnerParam = RouteHelper.getQueryStringOrHashParam("partner");
      var partner = this.getOrgInContextFromSession();
      // If there's not one already set then try to default it
      // OR if there's a parameter in the url ALWAYS prefer that (for when Chris is demoing he doesn't want funtown to take over)
      if (!partner || this.differentPartnerOrgIsInContext(partner, partnerParam)) {
        var userProfileSore = useUserProfileStore();
        // Check the Querystring
        if (partnerParam != null) {
          var isAdminOfThisOrg = this.isAdminOf(partnerParam, userProfileSore.getViewingUserProfile, false, true);
          const comingFromExternalSite = document.referrer && !document.referrer.includes("shrpa.com");
          Logger.info(`orgInContext present: ${partnerParam}, isAdmin=${isAdminOfThisOrg}, fromExternal=${comingFromExternalSite}`);
          // If they're coming from external assume it's a partner site so set the siteUri (otherwise we sometimes use this param to set the orgInContext)
          // Or if they're not an admin then we should set the uri (since the partner site vs admin dashboard pivots on that)
          const setSiteUri = comingFromExternalSite || !isAdminOfThisOrg;
          partner = await this.setOrgInContextLocal(partnerParam, setSiteUri);
        } else if (userProfileSore.getViewingUserProfile?.adminOf?.length > 0) {
          // If they're an admin default the orgInContext
          // Just default to the first if they have many
          partner = await this.setOrgInContextLocal(userProfileSore.getViewingUserProfile.adminOf[0], false);
        }
      }

      return partner;
    } catch (error) {
      Logger.error("defaultOrgInContext check failed:" + error);
      return null;
    }
  },

  // Returns true if there is a partner queryparam AND it's different from what's in context
  differentPartnerOrgIsInContext(currentContext: PartnerNavInfo | null, requestedInQuery: string | null): boolean {
    // Both of these must be set for them to be different
    if (currentContext == null || requestedInQuery == null) return false;

    // Case insensitive compare of either id or uniqueName fields.
    var isDifferent = currentContext.customerId.toUpperCase() !== requestedInQuery.trim().toUpperCase() && currentContext.uniqueName.toUpperCase() !== requestedInQuery.trim().toUpperCase();
    if (isDifferent) {
      Logger.info(`different orgInContext from incoming: InContextId=${currentContext.customerId}, queryParam=${requestedInQuery}`);
    }
    return isDifferent;
  },

  getOrgInContextFromSession(): PartnerNavInfo | null {
    return JSON.parse(sessionStorage.getItem("shrpaPartnerv2")) as PartnerNavInfo;
  },

  async setOrgInContextLocal(partnerParam: string, setSiteUri: boolean): Promise<PartnerNavInfo | null> {
    Logger.info("setOrgInContextLocal to: " + partnerParam);
    const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/config/partners/${partnerParam}/nav`);
    if (data) {
      return this.setOrgInContext(
        setSiteUri ? data.topNavBackLink : null,
        data.topNavLogoUri,
        data.uniqueName,
        data.customerId,
        data.type,
        data.name,
        data.immutableId,
        data.isDisabled,
        data.isSpotlight,
        data.enableCreateAdventures,
        data.trialEndDate,
        data.paymentStatus
      );
    } else {
      return null;
    }
  },

  getCommunityIdInContext(): string | null {
    // Passing in null for profile since we expect orgInContext to be set if it's going to be
    var org = this.getOrgInContextFromSession();
    return org?.customerId;
  },
};
