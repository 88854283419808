<template>
  <MenuLinksList :linksList="communityIdInContext ? navLinksAdmin : []" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OrgContext from "@logic/OrgContext";
import { useUserProfileStore } from "@stores/userProfileStore";
import { mapState } from "pinia";

// Types
import { NavigationMenuItem } from "@contracts/navigationMenuItem";

// Components
import MenuLinksList from "@components/Navigation/MenuLinksList.vue";
import FeatureFlags from "@logic/FeatureFlags";

// NOTE: Currently using the dashboard redirect parameter.
// An alternative would be to get the community in context here (but we'd have to react to dropdown changes on the dashboard anyway...)

export default defineComponent({
  name: "CustomerAdminLeftNav",

  components: {
    MenuLinksList,
  },

  data() {
    return {
      currentRouteName: "",
      communityIdInContext: "",
      hotelInContext: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile"]),
    orgInContext() {
      return this.globalRoot.orgInContext;
    },
    navLinksAdmin(): Array<NavigationMenuItem> {
      const isDisabled = this.orgInContext?.isDisabled;
      const isSpotlight = this.orgInContext?.isSpotlight;
      const showInpirationSection = FeatureFlags.isFlagActive("LeftNavInspirationSection");
      // prettier-ignore
      return FeatureFlags.isFlagActive('NewCustomerLeftNav') ? [
          { text: "Dashboard", isSubItem: false, titleIcon: { name: "dashboard_2" }, to: { name: "CommunityAdminDashboard", params: { communityId: this.communityIdInContext } } },

          { text: "Inspiration Source", isSubItem: false, titleIcon: { name: "bulb_2", size: 26 }, to: { name: "IndustryInsights", params: { pageId: this.communityIdInContext } }, isHidden: isSpotlight || !showInpirationSection },
          { text: "Visit Inspiration", isSubItem: true, to: { name: "VisitInspiration", params: { pageId: this.communityIdInContext } }, isHidden: isSpotlight || !showInpirationSection, isWithComingSoonBadge: true, isDisabled: true },
          { text: "Industry Insights", isSubItem: true, to: { name: "IndustryInsights", params: { pageId: this.communityIdInContext } }, isHidden: isSpotlight || !showInpirationSection },

          { text: "Collaboration Hub", isSubItem: false, titleIcon: { name: "collab_2", size: 23 }, to: { name: "PaidCollabCommunityAdmin", params: { communityId: this.communityIdInContext } }, isHidden: isDisabled },
          { text: "Creator Visits", isSubItem: true, to: { name: "PaidCollabCommunityAdmin", params: { communityId: this.communityIdInContext } }, isHidden: isDisabled || isSpotlight },
          { text: "Community Uploads", isSubItem: true, to: { name: "UserUploadCampaigns", params: { pageId: this.communityIdInContext } }, isHidden: isDisabled },
          { text: "Create an Adventure", isSubItem: true, to: { name: "MyItineraries", params: { creatorId: this.getViewingUserProfile?.sherpaId } }, isHidden: isDisabled || this.orgInContext?.enableCreateAdventures !== true },
          { text: "Content Launchpad", isSubItem: false, titleIcon: { name: "rocket_2", size: 23 }, to: { name: "CommunityPhotosV2", params: { pageId: this.communityIdInContext } } },
          { text: "Content Library", isSubItem: true, to: { name: "CommunityPhotosV2", params: { pageId: this.communityIdInContext } } },
          { text: "Website Enhancements", isSubItem: true, aliases: ["CustomIntegration", "PhotoWallAdmin", "AdventureSummary"], to: { name: isSpotlight ? "WidgetDirections" : "AdventureWidgets", params: { pageId: this.communityIdInContext } }, isHidden: isDisabled, isHighlightingOff: true },
          { text: "Adventure Wall", isSubItem: true, isSubSubItem: true, aliases: ["CustomIntegration"], to: { name: "AdventureWidgets", params: { pageId: this.communityIdInContext } }, isHidden: isDisabled || isSpotlight },
          { text: "Photo Wall", isSubItem: true, isSubSubItem: true, aliases: ["PhotoWallAdmin"], to: { name: "WidgetDirections", params: { pageId: this.communityIdInContext } }, isHidden: isDisabled },
          { text: "Adventure Posts", isSubItem: true, isSubSubItem: true, to: { name: "AdventureSummary", params: { pageId: this.communityIdInContext } }, isWithBetaBadge: false, isHidden: isDisabled || isSpotlight },
          { text: "Social Snapshots", isSubItem: true, to: { name: "ShareStopSummaryV2", params: { pageId: this.communityIdInContext } }, isHidden: isDisabled || isSpotlight },
          { text: "Flyer Studio", isSubItem: true, to: { name: "PrintMedia", params: { pageId: this.communityIdInContext } }, isHidden: isDisabled || isSpotlight },
        
          { text: "Settings", isSubItem: false, titleIcon: { name: "gear_2" }, to: { name: "CommunityPageAdmin", params: { pageId: this.communityIdInContext } }, isDisabled: isDisabled },
          { text: "Destination", isSubItem: true, to: { name: "CommunityPageAdmin", params: { pageId: this.communityIdInContext } }, isHidden: isDisabled },
          { text: "Subscription / Billing", isSubItem: true, to: { name: "SubscriptionSettings", params: { pageId: this.communityIdInContext } } },
          { text: "Users", isSubItem: true, to: { name: "UserAdmin", params: { pageId: this.communityIdInContext } }, isHidden: isDisabled },
          
        ] : [
          { text: "Dashboard", isSubItem: false, titleIcon: { name: "dashboard_2" }, to: { name: "CommunityAdminDashboard", params: { communityId: this.communityIdInContext } } },
          { text: "Content Creation", isSubItem: false, titleIcon: { name: "aperture_2" }, to: { name: "PaidCollabCommunityAdmin", params: { communityId: this.communityIdInContext } }, isHidden: isDisabled, },
          { text: "Creator Visits", isSubItem: true, to: { name: "PaidCollabCommunityAdmin", params: { communityId: this.communityIdInContext } }, isHidden: isDisabled },
          { text: "Community Uploads", isSubItem: true, to: { name: "UserUploadCampaigns", params: { pageId: this.communityIdInContext } }, isHidden: isDisabled },
          { text: "Create an Adventure", isSubItem: true, to: { name: "MyItineraries", params: { creatorId: this.getViewingUserProfile?.sherpaId } }, isHidden: isDisabled || this.orgInContext?.enableCreateAdventures !== true },
          { text: "My Content", isSubItem: false, titleIcon: { name: "briefcase_2" }, to: { name: "CommunityPhotosV2", params: { pageId: this.communityIdInContext } } },
          { text: "Photos & Videos", isSubItem: true, to: { name: "CommunityPhotosV2", params: { pageId: this.communityIdInContext } } },
          // Deprecated with content rights change { text: "Adventures", isSubItem: true, to: { name: "CommunityAdventureApproval", params: { pageId: this.communityIdInContext } }, isHidden: isDisabled },
          { text: "Content Uses", isSubItem: false, titleIcon: { name: "check-circle_2-5" }, to: { name: "WidgetDirections", params: { pageId: this.communityIdInContext } }, isHidden: isDisabled, },
          { text: "Adventure Wall", isSubItem: true, aliases: ["CustomIntegration"], to: { name: "AdventureWidgets", params: { pageId: this.communityIdInContext } }, isHidden: isDisabled, },
          { text: "Adventure Previews", isSubItem: true, to: { name: "AdventureSummary", params: { pageId: this.communityIdInContext } }, isWithBetaBadge: false, isHidden: isDisabled },
          { text: "Photo Wall", isSubItem: true, aliases: ["PhotoWallAdmin"], to: { name: "WidgetDirections", params: { pageId: this.communityIdInContext } }, isHidden: isDisabled },
          { text: "Share on Social", isSubItem: true, to: { name: "ShareStopSummaryV2", params: { pageId: this.communityIdInContext } }, isHidden: isDisabled },
          { text: "Print Media", isSubItem: true, to: { name: "PrintMedia", params: { pageId: this.communityIdInContext } }, isHidden: isDisabled },
          { text: "Settings", isSubItem: false, titleIcon: { name: "gear_2" }, to: { name: "CommunityPageAdmin", params: { pageId: this.communityIdInContext } }, isDisabled: isDisabled },
          { text: "Destination Page", isSubItem: true, to: { name: "CommunityPageAdmin", params: { pageId: this.communityIdInContext } }, isHidden: isDisabled },
          { text: "Subscription", isSubItem: true, to: { name: "SubscriptionSettings", params: { pageId: this.communityIdInContext } } },
          { text: "Users", isSubItem: true, to: { name: "UserAdmin", params: { pageId: this.communityIdInContext } }, isHidden: isDisabled },
        ];
    },
  },

  watch: {
    $route(to, from) {
      this.setRouteName();
      console.info(this.currentRouteName);
    },
    // This would allow us to avoid the redirect through dashboard routes
    "globalRoot.orgInContext": {
      handler: async function (orgInContext) {
        if (orgInContext && orgInContext?.customerId != null) {
          this.setupOrgInContext();
        }
      },
    },
  },

  async mounted() {
    this.setRouteName();
    this.setupOrgInContext();
  },

  methods: {
    setRouteName() {
      this.currentRouteName = this.$router.currentRoute.value.name?.toString();
    },
    setupOrgInContext() {
      const orgInContext = OrgContext.getOrgInContext(this);

      if (orgInContext) {
        this.communityIdInContext = orgInContext?.customerId;
        this.hotelInContext = OrgContext.isOrgInContextHotel(this);
      }
    },
    isActive(name, aliases = []): boolean {
      return name === this.currentRouteName || aliases.includes(this.currentRouteName);
    },
  },
});
</script>
